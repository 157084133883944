/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
var o;
!function (o) {
  const t = (o, t, n, e) => {
      let i = t,
        c = t;
      const l = n >>> 1,
        r = o[i - 1];
      for (; c <= l;) {
        c = i << 1, c < n && e(o[c - 1], o[c]) < 0 && ++c;
        const t = o[c - 1];
        if (e(t, r) <= 0) break;
        o[i - 1] = t, i = c;
      }
      o[i - 1] = r;
    },
    n = (o, t) => o < t ? -1 : o > t ? 1 : 0;
  function e(o, e, i, c) {
    void 0 === e && (e = 0), void 0 === i && (i = o.length), void 0 === c && (c = n);
    for (let n = i >>> 1; n > e; n--) t(o, n, i, c);
    const l = e + 1;
    for (let n = i - 1; n > e; n--) {
      const i = o[e];
      o[e] = o[n], o[n] = i, t(o, l, n, c);
    }
  }
  function* i(o, e, i, c) {
    void 0 === e && (e = 0), void 0 === i && (i = o.length), void 0 === c && (c = n);
    for (let n = i >>> 1; n > e; n--) t(o, n, i, c), yield;
    const l = e + 1;
    for (let n = i - 1; n > e; n--) {
      const i = o[e];
      o[e] = o[n], o[n] = i, t(o, l, n, c), yield;
    }
  }
  o.sort = e, o.iterableSort = i;
}(o || (o = {}));
const t = o;
export { t as default };